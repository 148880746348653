.tooltip {
  position: relative;
}

.tooltip-text {
  position: absolute;
  visibility: hidden;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}
